import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";


import Header from "../../Components/UI/Header/Header";
import CavityTartarSkeleton from "../../Components/UI/skeleton/cavityTartarSkeleton";
import { getBinaryDataOfImage } from "../../Redux/Services/AuthService"

import { getAlignerReportById } from "../../Redux/Services/AlignerService";
import CardSlider from "./Components/CardSlider";
import "./reportHistory.css";

const ReportHistory = (props) => {
  const [sliderImages, setSliderImages] = useState([])
  const [binaryData, setBinaryData] = useState([])
  const [reportImagesWithOutAligner, setReportImagesWithOutAligner] = useState([]);

  const teethList = [
    "front_teeth",
    "upper_jaw",
    "lower_jaw",
    "left_side",
    "right_side",
    "aligner_front_teeth",
    "aligner_upper_jaw",
    "aligner_lower_jaw",
    "aligner_left_side",
    "aligner_right_side",
  ];

  useEffect(() => {
    if (reportImagesWithOutAligner.length > 0) {
      getImageFromBucket(reportImagesWithOutAligner, setBinaryData)
    }

  }, [reportImagesWithOutAligner])


  useEffect(() => {
    if (reportImagesWithOutAligner.length === binaryData.length) {
      const imagesData = convertBinaryDataToBlob(reportImagesWithOutAligner, binaryData);
      setSliderImages(imagesData)
    }
  }, [binaryData])

  const getImageFromBucket = (arrayItems, setFn) => {
    arrayItems.forEach(async (obj, index) => {
      await getBinaryDataOfImage(obj.image).then((res) => {
        if (res.status === 200) {
          setFn(prevData => [...prevData, res.data.data])
        }
      }).catch((err) => {
      })
    })
  }

  const convertBinaryDataToBlob = (arrayItems, binaryImgData) => {
    return arrayItems.map((obj) => {
      let selected = binaryImgData.find(binaryObject => obj.image === binaryObject.imageName)
      const byteArray = new Uint8Array(selected.binaryData.data); // Convert the array of integers to a byte array
      const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
      const imageUrl = URL.createObjectURL(blob);
      return { name: obj.name, imageUrl };
    })
  }


  useEffect(() => {
    getAlignerReportById(props.match.params.reportId)
      .then((res) => {
        const resObj = res.data.data;
        const teethPhotosWithoutAligner = [];
        teethList.forEach((item, index) => {
          teethPhotosWithoutAligner.push({
            name: teethList[index],
            image: resObj[teethList[index]].split("/").pop(),
          });
        })
        setReportImagesWithOutAligner(teethPhotosWithoutAligner);
      })
      .catch((err) => { });
  }, []);

  return (
    <Container fluid className="dental-risk-bg min-vh-100">
      <Header title="Report History" routeRedirection="/total-test" />
      {
        reportImagesWithOutAligner.length > 0 && reportImagesWithOutAligner.length === sliderImages.length ?
          <Row>
            <Col className="px-0 overflow">
              <div className="mt-4 pt-5">

                <div className="shadow bg-white mx-3 my-3 pb-3 border-radius-15px border animate__animated animate__fadeInLeft">
                  <h6 className="text-uppercase text-center pt-3 pb-1">Photos without aligner</h6>
                  <CardSlider sliderImages={sliderImages.slice(0, 5)} />
                </div>

                <div className="shadow bg-white mx-3 my-3 pb-3 border-radius-15px animate__animated animate__fadeInRight">
                  <h6 className="text-uppercase text-center pt-3 pb-1">Photos with aligner</h6>
                  <CardSlider sliderImages={sliderImages.slice(5, 10)} />
                </div>

              </div>
            </Col>
          </Row>
          : <CavityTartarSkeleton />
      }
    </Container>
  );
};

export default ReportHistory;
