import axios from "axios";
import { API_HOST } from "./BaseUrl";

export const getAppsDetails = (appUrl) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-apps/details`, { appUrl }, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};
export const  getBinaryDataOfClinicImage = (imageName) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin

  };

  return new Promise((resolve, reject) => {
    return axios
      .get(
        API_HOST + `binaryDataOfClinicImage/${imageName}`,
        {
          headers
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const  getAlignerAppVersion = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin

  };

  return new Promise((resolve, reject) => {
    return axios
      .get(
        API_HOST + `update-aligner-version`,
        {
          headers
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}