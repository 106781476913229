import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";

const ProfileSkeleton = () => {
    return (
        <Row className="mx-0 justify-content-center">
            <Col xs={12} className=" text-center my-3">
                <Skeleton animation="wave" height={25} width="60%" />
                <Skeleton animation="wave" height={25} width="30%" />
                <Skeleton animation="wave" height={25} width="60%" />

            </Col>
            <Col className="mt-3">
                <Skeleton animation="wave" height={25} width="90%" />
                <Skeleton animation="wave" height={25} width="90%" />
            </Col>
        </Row>
    )
}

export default ProfileSkeleton