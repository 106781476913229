import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";

import CameraModuleBackground from "../../UI/CameraModule/CameraModuleBackground";
import BlurAndBrightnessModal from "../../UI/Modal/BlurAndBrightnessModal";
import { GetMobileOperatingSystem } from "./common";
import "./CameraModule.css";

const CameraModuleReshot = (props) => {
  const imgRef = useRef("");
  let imageUrl = props.state?.dataUri;
  let demoImage = props.state?.demoImage;
  let imgCount = props.state?.imgCount;
  const demoImageData = props.state?.data;
  const isReshoot = props.state?.isReshoot;
  const isQuickScan = props.state?.isQuickScan;
  let history = useHistory();

  const [canvaImg, setCanvaImg] = useState("");
  const [operatingSystem, setOperatingSystem] = useState('');
  const [isBlur, setIsBlur] = useState(false);
  const [isLowBrightness, setIsLowBrightness] = useState(false);

  useEffect(() => {
    const image = document.getElementById("source");
    const getOperatingSystem = GetMobileOperatingSystem();
    croppingImageUsingCanva(image.src);
    // checkBlurImage(image.src)
    setOperatingSystem(getOperatingSystem);

    if (getOperatingSystem !== 'iOS') {
      // Getting image brigntness
      getImageLightness(imageUrl, function (brightness) {
        if (brightness < 60) {
          setTimeout(() => {
            setIsLowBrightness(true);
          }, 500);
        }
      });

      // loadImageAndCheckBlur(imageUrl);

    }
  }, []);

  const loadImageAndCheckBlur = (imageUrl) => {

    const image = new Image();
    image.crossOrigin = 'anonymous'; // Enable cross-origin access if needed
    image.src = imageUrl;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0);

      // Apply a simple blur filter to reduce noise
      const blurRadius = 3;
      ctx.filter = `blur(${blurRadius}px)`;

      // Draw the blurred image back on the canvas
      ctx.drawImage(canvas, 0, 0);

      // Get the pixel data
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      // Reset the filter to ensure it doesn't affect subsequent operations
      ctx.filter = 'none';

      // Now you can use imageData for blur detection
      // setIsBlur(isImageBlurry(imageData));
    };



  };

  const calculateLaplacian = (imageData) => {
    const width = imageData.width;
    const height = imageData.height;
    const data = imageData.data;

    const laplacianData = new Float32Array(width * height);

    for (let y = 1; y < height - 1; y++) {
      for (let x = 1; x < width - 1; x++) {
        const idx = (y * width + x) * 4;

        const laplacian =
          data[idx - 4] + data[idx + 4] + data[idx - width * 4] + data[idx + width * 4] - 4 * data[idx];

        laplacianData[y * width + x] = laplacian;
      }
    }

    return laplacianData;
  }

  const isImageBlurry = (imageData) => {
    const laplacianData = calculateLaplacian(imageData);

    const sum = laplacianData.reduce((acc, val) => acc + Math.abs(val), 0);
    const average = sum / laplacianData.length;
    const fixedThreshold = 0.95

    return average < fixedThreshold;
  }


  const croppingImageUsingCanva = (imageUrl) => {
    crop(imageUrl, 3 / 2).then(canvas => {
      setCanvaImg(canvas.toDataURL("image/jpeg", 0.75));
      // document.body.appendChild(canvas);
    });
  };

  const crop = (url, aspectRatio) => {
    return new Promise((resolve) => {
      // this image will hold our source image data
      const inputImage = new Image();

      // we want to wait for our image to load
      inputImage.onload = () => {
        // let's store the width and height of our image
        const inputWidth = inputImage.naturalWidth;
        const inputHeight = inputImage.naturalHeight;

        // get the aspect ratio of the input image
        const inputImageAspectRatio = inputWidth / inputHeight;

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }

        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * 0.5;
        const outputY = (outputHeight - inputHeight) * 0.5;

        // create a canvas that will present the output image
        const outputImage = document.getElementById("canvas");

        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext("2d");
        ctx.drawImage(inputImage, outputX, outputY);
        resolve(outputImage);
      };

      // start loading our image
      inputImage.src = url;
    });
  };

  const handleReshootRedirect = () => {
    props.reshoot(true)
    props.setCamera(true)

    // history.push("/camera-module-first", {
    //   demoImage,
    //   imgCount: imgCount,
    //   data: demoImageData,
    //   isQuickScan
    // });
  };

  const handleSubmit = () => {
    const allTheImages = [...props.myImages];
    const isImg = allTheImages.some((img) => imageUrl === img);
    if (!isImg) {
      props.addImage({ imageUrl: canvaImg, imgCount });
      props.reportUpload({ imageUrl: canvaImg, imgCount });
    }
    props.reshoot(true)
    props.setCameraModule(true)
  };

  const getImageLightness = (imageSrc, callback) => {
    var img = document.createElement("img");
    img.src = imageSrc;
    img.style.display = "none";
    document.body.appendChild(img);

    var colorSum = 0;

    img.onload = function () {
      // create canvas
      var canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      var data = imageData.data;
      var r, g, b, avg;

      for (var x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      var brightness = Math.floor(colorSum / (this.width * this.height));
      callback(brightness);
    };
  };

  return (
    <Container
      fluid
      className="reshoot-container position-absolute p-0 min-vh-100"
    >
      <CameraModuleBackground />
      <Row className="align-items-end">
        {
          demoImage && (
            <Col lg={8} xs={12} className="d-flex justify-content-around mx-auto">
              <div className="camera-module-overlay-images">
                {demoImage !== null ? (
                  <div>
                    {
                      (demoImage && !isQuickScan) && (
                        <>
                          <img aria-label="image" src={demoImage} />
                          <div className="demo-label position-absolute d-flex align-items-center justify-content-center w-100 left-0">
                            <p className="black-color bg-white font-size-18 font-weight-bold">
                              Demo
                            </p>
                          </div>
                        </>
                      )
                    }
                  </div>
                ) : (
                  <img
                    aria-label="image"
                    src="https://www.trucaredentistry.com/blog/wp-content/uploads/How-to-strengthen-loose-teeth-1.png"
                  />
                )}
                <div className="d-flex justify-content-start close-image-icon">
                  <i onClick={handleReshootRedirect} className="fas fa-times"></i>
                </div>
              </div>
            </Col>
          )
        }
        <Col lg={8} xs={12} className={`d-flex justify-content-around mx-auto ${isQuickScan ? 'mt-5 pt-5' : ''}`}>
          <div className="position-relative px-4 mb-4">
            {/* <canvas ref={imgRef} /> */}
            <canvas className="crop-image" id="canvas"></canvas>
            <img
              className="d-none"
              aria-label="image"
              id="source"
              src={imageUrl}
            />
            <canvas hidden id="canvaoutput" />
            {/* {canvaImg && (
              <img className="crop-image" src={canvaImg} alt="cropImage" />
            )} */}
            {!canvaImg && (
              <Skeleton animation="wave" width={300} height={350} />
            )}

            <Button
              onClick={handleReshootRedirect}
              style={{ left: isBlur || isLowBrightness ? "45%" : "30%" }}
              className="reshoot-btn reshoot-left-icon rounded-pill bg-white"
              variant="primary"
              size="sm"
            >
              <i className="fas fa-redo font-size-22 primary-color"></i>
            </Button>

            {(!isBlur && !isLowBrightness) && (
              <Button
                onClick={handleSubmit}
                className="reshoot-btn reshoot-right-icon rounded-pill"
                variant="primary"
                size="sm"
              >
                <i className="fas fa-check font-size-22"></i>
              </Button>
            )}

            {(isBlur || isLowBrightness) && <BlurAndBrightnessModal
              isOpenDialog={isBlur || isLowBrightness ? true : false}
              isBlur={isBlur}
              isLowBrightness={isLowBrightness}
            />}


          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    myImages: state.image,
    reportState: state.report,
  };
};

const mapDespatchToProps = (dispatch) => {
  return {
    addImage: (imageData) => {
      dispatch({ type: "ADD_IMAGE", payload: imageData });
    },
    reportUpload: (report) => {
      dispatch({ type: "USER_REPORT", report });
    },
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(CameraModuleReshot);
