import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import { connect } from "react-redux";
import classes from "./signUp.module.css";
import { Button, CircularProgress } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import "./signup.css";
import moment from "moment";
import InputPassword from "../../UI/Input/InputPassword";
import { registerUserAction } from "../../../Redux/Action/AuthAction";
import SnackBarMessages from "../../UI/Snackbar/Snackbar";
import Header from "../../UI/Header/Header";
import { Link } from "react-router-dom";

const Signin = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState("");

  let history = useHistory();
  const userDetails = JSON.parse(localStorage.getItem("signupDetails"));
  const handleLoginNavigation = () => {
    history.push("/login", {
      comeBack:
        props.location?.state?.comeBack !== undefined ? true : undefined,
    });
  };

  useEffect(() => {
    if (props.signupResponse?.message) {
      setSnackbarData({ message: props.signupResponse.message, error: true });
    }
    if (props.signupResponse?.response) {
      history.push("/verify-user-code", {
        email: props.signupResponse.response.data.email,
        comeBack: undefined,
      });
    }
    setLoading(false);
  }, [props.signupResponse]);

  return (
    <Container fluid>
      {snackbarData.error && (
        <SnackBarMessages
          open={true}
          type="error"
          position="top-right"
          timing={5000}
          message={snackbarData.message}
        />
      )}
      <Row className="mb-5 pb-4">
        <Header title="Create Account" routeRedirection="/login" />
        {/* <Col xs={6}>
          <div className={classes["sign-up-page"]}>
            <span className={classes["circle"]}></span>
            <span className={classes["circle-text"]}>1 of 2</span>
          </div>
        </Col> */}
        {/* <Col xs={6}>
          <div className={`mt-4 ${classes["signup-header"]}`}>
            <h4>Create Account</h4>
            <p>Basic Details</p>
          </div>
        </Col> */}
      </Row>

      <Formik
        validate={(values) => {
          let errors = {};
          if (!values.first_name) {
            errors.first_name = "First Name is Required";
          }
          if (!values.last_name) {
            errors.last_name = "Last Name is Required";
          }
          if (!values.email) {
            errors.email = "Email is Required";
          } else if (!EmailValidator.validate(values.email)) {
            errors.email = "Invalid email address.";
          }
          const phoneRegExp =
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

          if (!values.phone_number) {
            errors.phone_number = "cell/Mobile number is Required";
          } else if (!phoneRegExp.test(values.phone_number)) {
            errors.phone_number = "Invalid Phone number Number";
          }
          if (!values.dob) {
            errors.dob = "Date of Birth is Required";
          }
          if (!values.gender) {
            errors.gender = "Gender is Required";
          }

          if (!values.password) {
            errors.gender = "Gender is Required";
          }
          if (!values.zip_postal_code) {
            errors.gender = "Gender is Required";
          }

          return errors;
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required("First Name is required"),
          last_name: Yup.string().required("Last Name is required"),
          email: Yup.string().email().required(" Email is Required"),
          phone_number: Yup.number()
            .required("Phone number Is required")
            .min(6, "Phone Number is too short - should be 6 chars minimum."),
          dob: Yup.string()
            .required("Please enter your dob")
            .test("DOB", "User must be atleast 3 years old", (value) => {
              return moment().diff(moment(value), "years") >= 3;
            }),
          password: Yup.string()
            .required("No password provided.")
            .min(8, "Setup your 8 character password"),
          zip_postal_code: Yup.string().required("ZIP/Postal Code is Required"),
          agree: Yup.boolean()
            .required("Accept Terms & Conditions is required")
            .oneOf([true], "Accept Terms & Conditions is required"),
        })}
        initialValues={{
          first_name: userDetails ? userDetails.first_name : "",
          last_name: userDetails ? userDetails.last_name : "",
          email: userDetails ? userDetails.email : "",
          phone_number: userDetails ? userDetails.phone_number : "",
          dob: userDetails ? userDetails.dob : null,
          gender: userDetails ? userDetails.gender : "",
          password: "",
          zip_postal_code: "",
          agree: userDetails ? userDetails.agree : false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          props.dispatch(registerUserAction(values));
          setLoading(true);
          // const local = localStorage.setItem(
          //   "signupDetails",
          //   JSON.stringify(values)
          // );
          // history.push("/sign-up", {
          //   values,
          //   // comeBack:
          //   //   props.location.state.comeBack !== undefined ? true : undefined,
          // });
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          const date1 = Date.now();
          const date = Date.now(date1);
          return (
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-end">
                <span style={{ color: "red" }}>*</span>Required fields
              </div>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                First Name<span style={{ color: "red" }}>*</span>
              </FormLabel>

              <div className="input-with-icon-placeholder position-relative">
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  className="form-input my-3  fontAwesome"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <i className="fa fa-user"></i>
                {errors.first_name && touched.first_name && (
                  <div className="text-danger mb-2 ml-2">
                    {errors.first_name}
                  </div>
                )}
              </div>

              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                Last Name<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <div className="input-with-icon-placeholder position-relative">
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  className="form-input my-3 fontAwesome"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <i className="fa fa-user"></i>
                {errors.last_name && touched.last_name && (
                  <div className="text-danger mb-2 ml-2">
                    {errors.last_name}
                  </div>
                )}
              </div>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                Email<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <div className="input-with-icon-placeholder position-relative">
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  className=" form-input my-3 fontAwesome"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <i className="fa fa-envelope"></i>
                {errors.email && touched.email && (
                  <div className="text-danger mb-2 ml-2">{errors.email}</div>
                )}
              </div>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                Cell/Mobile Number<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <div className="input-with-icon-placeholder position-relative">
                <input
                  id="phone_number"
                  name="phone_number"
                  type="number"
                  placeholder="Cell/Mobile Number"
                  className="form-input my-3 fontAwesome"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <i className="fa fa-mobile"></i>
                {errors.phone_number && touched.phone_number && (
                  <div className="text-danger mb-2 ml-2">
                    {errors.phone_number}
                  </div>
                )}
              </div>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                Date of Birth<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                className={` ${classes["MuiTypography-subtitle1"]}`}
              >
                <KeyboardDatePicker
                  // utils={DateFnsUtils}
                  openTo="year"
                  className="form-input my-3 py-3 fontAwesome "
                  format="MM/dd/yyyy"
                  placeholder="Date of Birth"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  id="dob"
                  name="dob"
                  helperText={null}
                  inputProps={{ readOnly: true }}
                  onBlur={handleBlur}
                  value={values.dob}
                  onChange={(value) => props.setFieldValue("dob", value)}
                  InputAdornmentProps={{ position: "start" }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  TextFieldComponent={(props) => (
                    <TextField {...props} onClick={(e) => setOpen(true)} />
                  )}
                />

                {errors.dob && touched.dob && (
                  <div className="text-danger mb-2 ml-2">{errors.dob}</div>
                )}
              </MuiPickersUtilsProvider>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                ZIP/Postal Code<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <div className="input-with-icon-placeholder position-relative">
                <input
                  id="zip_postal_code"
                  name="zip_postal_code"
                  type="text"
                  placeholder="ZIP/Postal Code"
                  className="form-input my-3 fontAwesome"
                  value={values.zip_postal_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <i className="fas fa-city"></i>
                {errors.zip_postal_code && touched.zip_postal_code && (
                  <div className="text-danger">{errors.zip_postal_code}</div>
                )}
              </div>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                Password<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <div className="input-with-icon-placeholder position-relative">
                <InputPassword
                  id="password"
                  name="password"
                  placeholder="Password"
                  error={errors.password}
                  value={values.password}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.password}
                />
              </div>
              <FormLabel
                component="legend"
                className="ml-3 pt-3 font-weight-500 text-dark "
              >
                Gender<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <FormControl component="fieldset" className="w-100">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender"
                  className="justify-content-around"
                  value={values.gender}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio color="primary" />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio color="primary" />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio color="primary" />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              {errors.gender && touched.gender && (
                <div className="text-danger mb-2 ml-2">{errors.gender}</div>
              )}
              <div
                className={`${classes["terms-condition"]} text-center mt-4 row px-3`}
              >
                <div className="col-1 pr-0 text-center">
                  <input
                    type="checkbox"
                    id="terms-condition"
                    name="agree"
                    className="position-relative"
                    value={values.agree}
                    checked={values.agree}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-11 pl-0">
                  <label htmlFor="terms-condition">
                    By logging in or creating an account, you agree to our{" "}
                    <br />
                    <Link to="/terms-of-use">Terms of Use</Link> and{" "}
                    <Link to="privacy-policy">Privacy Policy</Link>
                  </label>
                </div>
                <div className="col-12 text-center my-2">
                  {errors.agree && touched.agree && (
                    <div className="text-danger">{errors.agree}</div>
                  )}
                </div>
              </div>

              <div className="text-center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    // disabled={isSubmitting}
                    className="form-btn"
                  >
                    Submit
                  </Button>
                )}
              </div>

              <Row>
                <Col xs={12}>
                  <h4 className={`${classes["login-createOne"]} my-3`}>
                    <span>Already Registered? </span>
                    <button
                      type="button"
                      className="login-link-text"
                      onClick={handleLoginNavigation}
                    >
                      Sign-in
                    </button>
                  </h4>
                </Col>
              </Row>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    signupResponse: state.signup,
  };
};
// const mapDispatchToProps = (dispatch) => {
//     return {
//         signupAction: (signup) => dispatch(signupAction(signup))
//     }
// }

export default connect(mapStateToProps, null)(Signin);
