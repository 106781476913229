import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ShareIcon from "@material-ui/icons/Share";
import TeethImg from "../../../assets/Dashboard/reports/report-tooth.svg";
import MailCheckIcon from "../../../assets/icon/mail-check.svg";
import cssClasses from "./TotalTest.module.css";
import Header from "../../UI/Header/Header";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { FormLabel } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReportIcon from "../../../assets/icon/report-icon.svg";
// import {FaDownload} from "react-icons/fa";
import GetAppIcon from "@material-ui/icons/GetApp";
import CavityTartarSkeleton from "../../UI/skeleton/cavityTartarSkeleton";
import { getALignerReport, getAlignerReport } from "../../../Redux/Services/AlignerService";

const TotalTest = () => {
  const [allReports, setAllReports] = useState([]);
  const [isLoading, setLoading] = useState(true)
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"))?.user;

  useEffect(() => {
    setLoading(true)

    getAlignerReport()
      .then((res) => {
        setAllReports(res);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      });
  }, []);

  const dateFormat = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };

  const redirectToBranchesPage = (event, item) => {
    event.preventDefault();
    history.push(`/select-branches/${item._id}`, { userId: user._id });
  };

  return (
    <Container fluid>
      <Header title="TEST REPORTS" routeRedirection="/dashboard" />
      {
        isLoading ? <CavityTartarSkeleton /> :

          <Row className="px-3 margin-top-70px">
            {allReports.map((item, index) => {
              return (
                <Col
                  key={index}
                  xs={12}
                  className={`${cssClasses["totaltest-card"]} my-2 w-100 border-radius-10px animate__animated animate__fadeInUpBig`}
                >
                  <Link to={"/report-history/" + item._id}>
                    <Row>
                      <Col xs={8}>
                        <Row>
                          <Col xs={6}>
                            {" "}
                            <Row>
                            <Col xs={6} className="">
                                <Button
                                  className="min-width p-0"
                                // onClick={(event) =>
                                //   redirectToBranchesPage(event, item)
                                // }
                                >
                                  {/* <ShareIcon className="primary-color mx-4" />  */}
                                  <img className="w-18px" src={ReportIcon} alt="report-icon" />
                                </Button>
                              </Col>
                              <Col xs={6}>
                                <h6 className="black-color d-flex font-size-20 font-weight-500">
                                  {" "}
                                  week_#00{index}{" "}
                                </h6>
                              </Col>
                            </Row>

                          </Col>
                          <Col xs={2}>
                            {item.is_report_send_to_dentist && (
                              <span className="position-relative mail-check">
                                <img src={MailCheckIcon} alt="MailCheckIcon" />
                              </span>
                            )}
                          </Col>
                          <Col xs={2}>
                            {item.fullUrl && (
                              <div>
                                <GetAppIcon className="mx-4" color="success" />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={4} className="text-right">
                        <p className="black-color font-size-14">
                          <i className="far fa-calendar-alt mr-1"></i>
                          {dateFormat(item.createdAt)}
                          {/* {new Date(item.createdAt).toLocaleDateString()} */}
                        </p>
                      </Col>
                    </Row>
                    {!item.is_quick_scan && (
                      <>
                        {/* <Row className="align-items-center black-color my-2 font-size-14 justify-content-center">
                        <Col xs={3}>Cavity</Col>
                        <Col xs={6}>
                          <div className={`${cssClasses["cavity-bg"]} score-test`}>
                            <div
                              style={{
                                width: `calc(100% - ${item.report_score[0]?.overall_score[1] * 33.3}%)`,
                              }}
                            ></div>
                          </div>
                        </Col>
                        <Col xs={3}>{calculateCavityScore(item.report_score[0]?.overall_score[1])}</Col>
                      </Row> */}
                        {/* <Row className="align-items-center black-color font-size-14 justify-content-center">
                        <Col xs={3}>Tartar</Col>
                        <Col xs={6}>
                          <div className={`${cssClasses["tartar-bg"]} score-test`}>
                            <div
                              style={{
                                width: `calc(100% - ${item.report_score[0]?.overall_score[0] * 6.3}%)`,
                              }}
                            ></div>
                          </div>
                        </Col>
                        <Col xs={3}>{calculateTartarScore(item.report_score[0]?.overall_score[0])}</Col>
                      </Row> */}
                      </>
                    )}
                  </Link>
                  {/* {item?.report_send_to_dentist?.length > 0 && (
                <div className="my-3">
                  <Accordion className="w-100">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header"
                    >
                      <FormLabel
                        label="Drop down to know the report sent details"
                      />{" "}
                      <FormLabel>Click here to know the details</FormLabel>
                    </AccordionSummary>
                    {item.report_send_to_dentist.map((items, indices) => {
                      return (
                        <AccordionDetails key={indices}>
                          <Col
                            xs={12}
                            className={`${cssClasses["totaltest-cards"]}  w-100 border-radius-10px`}
                          >
                            <Row>
                              <Col>
                                <p className="black-color">Report Sent to </p>
                              </Col>
                              <span>:</span>
                              <Col>
                                {" "}
                                <p className="black-color">
                                  {items.branch_details}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {" "}
                                <p className="black-color">Patient Status </p>
                              </Col>
                              <span>:</span>
                              <Col>
                                {" "}
                                <p className="black-color ">
                                  {items.new_patient == true
                                    ? "New Patient"
                                    : "Existing Patient"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {" "}
                                <p className="black-color">Delivered date </p>
                              </Col>
                              <span>:</span>
                              <Col>
                                <p className="black-color">
                                  {" "}
                                  {dateFormat(items.report_sending_date)}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </AccordionDetails>
                      );
                    })}
                  </Accordion>
                </div>
              )} */}
                </Col>
              );
            })}

            {/* when there is no report list */}
            {allReports.length === 0 && (
              <div className="col-12 mt-5 text-center">
                <div>
                  <p className="black-color font-size-16 mb-5">
                    It seems you haven’t checked your teeth conditions yet
                  </p>
                  <div>
                    <img src={TeethImg} alt="dental risk score" />
                    <p className="black-color font-size-16 my-3">
                      Get to know your teeth a lot better
                    </p>
                  </div>

                  <Button className="my-5 form-btn font-size-18 font-weight-500 text-uppercase">
                    <Link
                      to="/instruction-for-use"
                      className="text-white w-100 h-100"
                    >
                      Take Scan
                    </Link>
                  </Button>
                </div>
              </div>
            )}
          </Row>}
    </Container>
  );
};
export default TotalTest;
