import axios from "axios";
import { API_HOST } from "./BaseUrl";

let refreshTokenTimeout;

const AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    hosts:window.location.origin
  },
  validateStatus: (status) =>
    status === 200 || status === 201 || status === 204,
});

const axiosJWT = axios.create();
let isHeadersSet = false;


axiosJWT.interceptors.request.use(
  async (config) => {
    const authToken = localStorage.getItem("authToken");
    let currentDate = new Date();
    const decodedToken = authToken ? JSON.parse(atob(authToken.split('.')[1])) : '';
    if (decodedToken?.exp * 1000 < currentDate.getTime()) {
      isHeadersSet = true;
      const userDetails = JSON.parse(localStorage.getItem('user'));
      const tokenRefresh = userDetails?.token;
      const email = userDetails.user.email;
      const responseData = await refreshToken(tokenRefresh, email);
      config.headers["Authorization"] = responseData.data.data.jwtToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const errorHandler = (error, reject) => {
  if (error && error.response) {
    reject({ ...error.response, isError: true });
  } else {
    reject({ message: "Server is disconnected!!!", isError: true });
  }
}

export const registerUserService = (request) => {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/register`, request.user, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        if (error && error.response?.status === 406) {
          reject({ message: "User is Already Registered!!!", isError: true });
        } else {
          errorHandler(error, reject);
        }
      });
  });
};

export const loginUserService = (request) => {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/login`, request.user, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Server is disconnected", isError: true });
        }
      });
  });
};

export const logoutUserService = () => {
  const authToken = localStorage.getItem("authToken");
  const quickScanCheckupValue = JSON.parse(localStorage.getItem("quickScanCheckup"))?.is_quickScan_checkup
  const refreshToken = JSON.parse(localStorage.getItem('user')).token;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .put(API_HOST + `aligner-user/logout`, { token: refreshToken }, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          stopRefreshTokenTimer();
          localStorage.setItem('quickScanCheckup', JSON.stringify({ is_quickScan_checkup: quickScanCheckupValue }))
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        localStorage.setItem('quickScanCheckup', JSON.stringify({ is_quickScan_checkup: quickScanCheckupValue }))
        stopRefreshTokenTimer();
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Server is disconnected", isError: true });
        }
      });
  });
};

export function startRefreshTokenTimer(token, refreshTokens) {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(token.split('.')[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - (60 * 1000);
  refreshTokenTimeout = setTimeout(async () => await refreshToken(refreshTokens), timeout);
}

export function stopRefreshTokenTimer() {
  localStorage.clear();
  clearTimeout(refreshTokenTimeout);
}

export function refreshToken(refreshTokens, email) {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/refresh-token`, { token: refreshTokens, email }, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('user', JSON.stringify(response.data.data));
          localStorage.setItem('authToken', response.data.data.jwtToken);
          // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        stopRefreshTokenTimer();
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
}

export const forgotPasswordService = (request) => {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/forgotpassword`, request.userEmail, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyCodeService = (request) => {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/verifyforgotpasswordcode`, request.verifyCode, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyUserCodeService = (request) => {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/emailverify`, request.verifyCode, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createNewPasswordService = (request) => {
  const headers = {
    "Content-Type": "application/json",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `aligner-user/resetpassword`, request.createPassword, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changePasswordService = (request) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `aligner-user/changePassword`, request.changePassword, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        if (error && error.response?.status === 401) {
          reject({ message: error.response.data.message, isError: true });
        } else {
          errorHandler(error, reject);
        }
      });
  });
};

export const reportUploadService = (request) => {
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  var blob = dataURItoBlob(request.report.imageUrl);
  var file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
  var formData = new FormData();
  formData.append("report_image", file);

  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `report/image`, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        return error;
      });
  });
};

export const userReportUpload = (request) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    createAlignerReport(request.reportUpload.reportImagesDataObj)
      .then((resUpdate) => {
        resolve(resUpdate);
      })
      .catch((err) => {
        if (err && err.response) {
          reject({ ...err.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export function createAlignerReport(reportData) {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `aligner/report`, reportData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data);
        }
        reject(response);
      })
      .catch((err) => {
        if (err && err.response) {
          reject({ ...err.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
}

export function mlFunction(imgData, obj) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    hosts:window.location.origin
  };

  const images = imgData.map((img) => {
    const splitImg = img.image_name ? img.image_name.split("/")[1] : '';
    return splitImg;
  }).filter(Boolean);


  // ['fr_c_106.jpeg', 'fr_t_7.jpeg']
  const imagesObj = {
    image: images,
    isQuickScan: obj.isQuickScan
  };

  return new Promise((resolve, reject) => {
    postReportImages(imagesObj, obj)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Call ML Api
export const postReportImages = (reqBody, obj) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `report/post-report-images`, reqBody, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          createNewReport(obj, [response.data])
            .then((resUpdate) => {
              resolve({ ...response, ...resUpdate });
            })
            .catch((err) => {
              // reject(err);
              if (err && err.response) {
                reject({ ...err.response, isError: true });
              } else {
                reject({ message: 'Network error', isError: true });
              }
            });
        }
      })
      .catch((error) => {
        // reject(error);
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

// -------------image to db-----------

function createNewReport(obj, mlReportData) {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  const reportData = { ...obj, report_score: [mlReportData.data] };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `report`, reportData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return generatePdf({ reportId: response.data.data.report._id })
            .then((redpdf) => {
              const imageData = [...obj.report_images];
              resolve(response.data.data);
            })
            .catch((err) => {
              reject(err);
            });
        }
        reject(response);
      })
      .catch((error) => {
        return error;
      });
  });
}

export function generatePdf(reportData) {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `report/pdf-download`, reportData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        return error;
      });
  });
}

function updateReport(authToken, reportData, reportId) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  const report = {
    report_score: [reportData.data],
    reportId,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .patch(API_HOST + `report/update`, report, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const updateReportData = (reportData) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `report/branch-details-update`, reportData, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        return error;
      });
  });
}

export const userProfileUpdateService = (request) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `aligner-user/updateUserProfile`, request.profile, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        return error;
      });
  });
};

export const postPopUpData = async (userdata) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `user-looking-for`, { userdata }, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        return error;
      });
  });
};

export const getReportsCount = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `report/count`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getLatestReport = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `report/latest-report`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllReports = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `report`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportById = (reportId) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `report/${reportId}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Questionnaire API
export const postQuestionnaireData = async (questionsData, isGeneratePdf, reportId) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `questionnaire`, questionsData, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (isGeneratePdf) {
            return generatePdf({ reportId })
              .then((redpdf) => {
                resolve(response);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve(response);
          }
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getQuestionnaireById = (userId) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `questionnaire/${userId}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Cavity and Tartar contents API
export const getCavityTartarContents = (reportId, cavityScore, tartarScore, isQuickScan) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST +
        `report/cavity-tartar/${reportId}/${cavityScore}/${tartarScore}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// insights API
export const getInsightsContents = (cavityScore, tartarScore) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `questionnaire/insights/${cavityScore}/${tartarScore}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Rating APIs
export const postRating = (requestBody) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `rating`, requestBody, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Resend verify code
export const ResendEmailVerifyCode = (requestBody) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `user/resendcode`, requestBody, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// -------------------popupdata-------------
export const getPopupDate = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `userprofile`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// -------------------popupdata_Update-------------
export const updatePopupDate = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `user-popup-date-update`, {}, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ---------------------------insurancepic--------------


export const insurancepic = (request) => {
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array   
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  var blob = dataURItoBlob(request);

  var file = new File([blob], "insurance_image.jpeg", { type: "image/jpeg" });

  var formData = new FormData();
  formData.append("insurance_image", file);

  const headers = {
    "Content-Type": "multipart/form-data",
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `user/insurance-pic`, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        return error;
      });
  });
};

export const imgtodb = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `user-insurance-details`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getLatestInsurance = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `latest-insurance`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBinaryDataOfImage = (imageName) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `binary/input/${imageName}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
};