
import axios from "axios";
import { API_HOST } from "./BaseUrl";

const axiosJWT = axios.create();
let isHeadersSet = false;
let refreshTokenTimeout;


axiosJWT.interceptors.request.use(
    async (config) => {
        const authToken = localStorage.getItem("authToken");
        let currentDate = new Date();
        const decodedToken = authToken ? JSON.parse(atob(authToken.split('.')[1])) : '';
        if (decodedToken?.exp * 1000 < currentDate.getTime()) {
            isHeadersSet = true;
            const userDetails = JSON.parse(localStorage.getItem('user'));
            const tokenRefresh = userDetails?.token;
            const email = userDetails.user.email;
            const responseData = await refreshToken(tokenRefresh, email);
            config.headers["Authorization"] = responseData.data.data.jwtToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export function startRefreshTokenTimer(token, refreshTokens) {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(async () => await refreshToken(refreshTokens), timeout);
}

export function stopRefreshTokenTimer() {
    localStorage.clear();
    clearTimeout(refreshTokenTimeout);
}

export function refreshToken(refreshTokens, email) {
    const headers = {
        "Content-Type": "application/json",
        hosts: window.location.origin
    };
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `aligner-user/refresh-token`, { token: refreshTokens, email }, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    localStorage.setItem('authToken', response.data.data.jwtToken);
                    // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                stopRefreshTokenTimer();
                if (error && error.response) {
                    reject({ ...error.response, isError: true });
                } else {
                    reject({ message: 'Network error', isError: true });
                }
            });
    });
}

export function getAlignerReport() {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
        hosts: window.location.origin
    };
    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + `aligner/report`, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
                reject(response);
            })
            .catch((err) => {
                if (err && err.response) {
                    reject({ ...err.response, isError: true });
                } else {
                    reject({ message: 'Network error', isError: true });
                }
            });
    });
}

export function getAlignerReportById(reportId) {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
        hosts: window.location.origin
    };
    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + `aligner/report/${reportId}`, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((err) => {
                if (err && err.response) {
                    reject({ ...err.response, isError: true });
                } else {
                    reject({ message: 'Network error', isError: true });
                }
            });
    });
}

export function getAlignerReportCount() {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
        hosts: window.location.origin
    };
    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + `aligner/report/count`, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((err) => {
                if (err && err.response) {
                    reject({ ...err.response, isError: true });
                } else {
                    reject({ message: 'Network error', isError: true });
                }
            });
    });
}

export function userInfo() {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
        hosts: window.location.origin
    }

    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + 'aligner-user', { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response)
                }
                reject(response)
            })
            .catch((err) => {
                if (err && err.response) {
                    reject({ ...err.response, isError: true });
                } else {
                    reject({ message: 'Network error', isError: true });
                }
            });
    });
}