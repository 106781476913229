import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { Button } from "@material-ui/core";
// import 'react-html5-camera-photo/build/css/index.css';

import { connect } from "react-redux";
import MessageModal from "./../../UI/Modal/message-modal";
import StepsModal from "./../../UI/Modal/steps-modal";
import LoginModal from "./../../UI/Modal/login-modal";
import ScanningTooth from "../../UI/animation/ScanningTooth";

import ToothPhotosSubmitModal from "./../../UI/Modal/ToothPhotosSubmitModal";
import { CameraStepsData } from "./../../Utils/camera-static-data/CameraStepsData";
import CameraModuleButton from "../../UI/CameraModule/CameraModuleButton";
import CameraModuleBottomImage from "../../UI/CameraModule/CameraModuleBottomImage";
import CameraModuleBackground from "../../UI/CameraModule/CameraModuleBackground";
import CameraCancelButton from "../../UI/CameraModule/CameraCancelButton";

import QuickScanModal from "../../UI/Modal/QuickScanModal";
import QuickScanDemoImages from "../../UI/Modal/QuickScanDemoImages";
import QuickScanTutorialDialog from "../../UI/Dialog/QuickScanTutorialDialog";
import MessageDialog from "../../UI/Dialog/messageDialog";
import "./CameraModule.css";
import CameraModuleFirst from "./CameraModuleFirst";

const TOTAL_IMAGE_COUNT = 5;

const CameraModuleTwo = (props) => {
  let history = useHistory();
  const [isCameraSecondModule, setCameraModule] = useState(true)
  const [isCamera, setCamera] = useState(true)      // if false, it's reshoot
  const [cameraFirstModuleData, setCameraData] = useState()
  const fullscreen = true;
  const stepsData = CameraStepsData;
  const isQuickScan = props.location.state?.isQuickScan;
  const getAlignerPhoto = JSON.parse(localStorage.getItem('alignerPhotos'));
  const getAlignerDetails = JSON.parse(localStorage.getItem('alignerDetails'));

  const [images, setImages] = useState(props.reportState.responseImages);
  const [showLogin, setShowLogin] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const [isSubmitAndShare, setIsSubmitAndShare] = useState(false);
  const [isShowMessagePopup, setIsShowMessagePopup] = useState(true);
  const [messageDialogData, setMessageDialogData] = useState({
    isShowDialog: true,
    message: `Please take all the photos without ${getAlignerDetails?.isAlignerCheckCompleted ? 'retainer' : 'aligner'}`
  });
  const [isWithOutAlignerPhotoTaken, setIsWithOutAlignerPhotoTaken] = useState(false);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(true);

  useEffect(() => {
    if (props.myImages.length > 0 || getAlignerPhoto) {
      setMessageDialogData({ isShowDialog: false, message: '' });
    }

    if (getAlignerPhoto) setIsWithOutAlignerPhotoTaken(true);

  }, []);

  useEffect(() => {
    setImages(props.reportState.responseImages);
    const myImagesLength = props.myImages.length;
    const responseImages = props.reportState.responseImages[myImagesLength - 1];
    if (responseImages?.image_name) {
      setIsPhotoUploaded(true);
    } else {
      if (myImagesLength) {
        setIsPhotoUploaded(false);
      } else {
        setIsPhotoUploaded(true);
      }
    }
  }, [props.myImages, props.reportState]);

  useEffect(() => {
    setImages(props.reportState.responseImages);

    if (props.myImages.length === TOTAL_IMAGE_COUNT && !getAlignerPhoto) {
      setMessageDialogData({
        isShowDialog: true,
        message: `Please take all the photos with 
        ${getAlignerDetails.isAlignerCheckCompleted ? 'retainer' : `aligner number ${getAlignerDetails.currentAligner + 1}`}`
      });
    }

  }, [props.myImages, props.reportState.responseImages]);

  const handleMessageDialogBox = () => {
    setMessageDialogData({ isShowDialog: false, message: '' });
    if (props.myImages.length === TOTAL_IMAGE_COUNT) {
      localStorage.setItem('alignerPhotos', JSON.stringify(props.reportState.responseImages));
      // props.clearImages();
      setIsWithOutAlignerPhotoTaken(true);
    }
  }

  const handleTakePhotoAnimationDone = (dataUri) => { };

  const handleReportSubmit = (isShareWithDentalOffice = false) => {
    if (authToken === null && (props.myImages.length >= 5 || isQuickScan)) {
      setShowLogin(true);
    } else {
      if (props.myImages.length === 10 || isQuickScan) {

        const reportImagesDataObj = {};
        const reportImages = [...props.reportState.responseImages];
        reportImages.forEach(item => {
          reportImagesDataObj[item.key] = item.image_name;
        });

        setLoader(true);
        props.reportUpload({ reportImagesDataObj });
        localStorage.removeItem('alignerPhotos');
      }
    }
  };

  const handleSignupNavigation = () => {
    history.push("/signup", { comeBack: true });
  };
  const handleLoginNavigation = () => {
    history.push("/login", { comeBack: true });
  };

  const redirectToReshoot = (img, index, imgContents) => {
    setCameraData({
      dataUri: img,
      demoImage: stepsData[index].image,
      imgCount: index + 1,
      data: stepsData[index],
      isReshoot: true,
      overlay: `Overlay${index + 1}`,
      isQuickScan,
    })
    setCamera(false)
    setCameraModule(false)
  };
  const closeMessageDialog = () => {
    setIsShowMessagePopup(false);
  };
  return (
    <>
      {isCameraSecondModule ? <Container fluid className="bg-container camera-preview p-0">
        <Camera
          onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          isFullscreen={fullscreen}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          isImageMirror={false}
          className="WebCam"
        ></Camera>
        <CameraModuleBackground />
        {messageDialogData.isShowDialog && <MessageDialog
          open={messageDialogData.isShowDialog}
          message={messageDialogData.message}
          onClose={handleMessageDialogBox}
        />}
        <Row className="align-items-start header-photo-count h-100 overflow-auto mx-0 w-100">
          <Col xs={12} className="text-right pt-2">
            <CameraCancelButton routeUrl="/dashboard" />
          </Col>
          <Col xs={12} className="px-0 text-center">
            {!isQuickScan ? (
              <>
                <Link to="/instruction-for-use">
                  <Button className="text-white font-weight-bold instructions-btn tertiary-bg my-2">
                    <i className="far fa-images mr-2 font-weight-bold font-size-16"></i>
                    Back to Tutorial
                  </Button>
                </Link>
                {props.myImages.length <= 9 && (
                  <StepsModal stepsData={stepsData} myImages={props.myImages} isPhotoUploaded={isPhotoUploaded} setCameraModule={setCameraModule} setCameraData={setCameraData} />
                )}
              </>
            ) : props.myImages.length > 0 ? (
              !showLoader && null

            ) : (
              <>
                {isShowMessagePopup && (
                  <QuickScanTutorialDialog
                    open={isShowMessagePopup}
                    message="Take the photo as close to the mouth as possible"
                    onClose={closeMessageDialog}
                  />
                )}
                {!isShowMessagePopup && (
                  <div>
                    <QuickScanDemoImages stepsData={CameraStepsData} />

                    <Button
                      className="text-center font-size-19 text-uppercase text-white primary-bg font-weight-bold"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setCameraData({
                          data: "",
                          imgCount: props.myImages.length + 1,
                          isQuickScan: true,
                        })
                      }}
                    >
                      Take Photos
                    </Button>

                  </div>
                )}
              </>
            )}

            {!authToken && showLogin ? (
              <LoginModal
                signup={handleSignupNavigation}
                login={handleLoginNavigation}
              />
            ) : undefined}
            {props.reportUploadState.message !== undefined &&
              props.reportUploadState.message !== "" && (
                <MessageModal
                  isQuestionnaireGiven={
                    props.reportUploadState?.response?.isQuestionnaireGiven
                  }
                  reportId={props.reportUploadState?.response?._id}
                  isQuickScan={isQuickScan}
                  isSubmitAndShare={false}
                  message="Thanks for submitting your photos."
                  isSuccess={
                    props.reportUploadState.message ===
                      "Something went wrong please try again!"
                      ? false
                      : true
                  }
                />
              )}
            {showLoader && !props.reportUploadState.message ? (
              <ScanningTooth />
            ) : (
              !showLogin &&
              !props.reportUploadState.message &&
              props.myImages.length === 10 && (
                <>
                  {isWithOutAlignerPhotoTaken && <ToothPhotosSubmitModal onSubmit={handleReportSubmit} isPhotoUploaded={isPhotoUploaded} />}
                </>
              )
            )}
          </Col>
          <Col xs={12} className="px-0">
            <Row className="align-items-end mx-0">
              <Col
                lg={8}
                xs={12}
                className="px-0 d-flex justify-content-around mx-auto"
              >
                {stepsData.map((block, index) => {
                  return (
                    <CameraModuleBottomImage
                      isImage={props.myImages[index] ? true : false}
                      className={isWithOutAlignerPhotoTaken ? 'show-first-five' : 'show-last-five'}
                      key={index}
                      count={index + 1}
                      image={props.myImages[index]}
                      onClick={() =>
                        redirectToReshoot(props.myImages[index], index)
                      }
                    />
                  )
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> : <CameraModuleFirst state={cameraFirstModuleData} isCamera={isCamera} setCameraModule={setCameraModule} reshoot={setCamera} />}

    </>

  );
};

const mapDespatchToProps = (dispatch) => {
  return {
    reportUpload: (reportUpload) => {
      dispatch({ type: "USER_REPORT_UPLOAD", reportUpload });
    },
    clearImages: () => {
      dispatch({ type: "REMOVE_ALL_IMAGES" });
    }
  };
};

const mapStateToProps = (state) => {
  return {
    myImages: state.image,
    reportState: state.report,
    reportUploadState: state.reportUpload,
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(CameraModuleTwo);