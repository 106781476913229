import axios from "axios";
import { API_HOST } from "./BaseUrl";

export const getBranches = () => {
    let authToken = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken,
      hosts:window.location.origin
    };
    return new Promise((resolve, reject) => {
      return axios
        .get(API_HOST + `activeBranch`,{ headers: headers })
        .then((response) => {
          if (response.status === 200) {
            return resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          if (error && error.response) {
            reject({ ...error.response, isError: true });
          } else {
            reject({ message: 'Network error', isError: true });
          }
        });
    });
  };