import { Button, Dialog, DialogActions } from '@material-ui/core';
import React from 'react';
import TorchIcon from './../../../assets/cameraModule/torch-icon.png';
import alignerPic from "../../../assets/alignCheckLogos/alignerImage.png"

const MessageDialog = (props) => {
    const { onClose, open, message } = props;

    const handleClose = () => {
        onClose(false);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const AlignerInfo = () => {
        return (
            <div className='d-flex flex-column align-items-center justify-content-center '>
                <p className="text-secondary text-center font-weight-bold  font-size-12 my-2">Ensure that the aligners are seated correctly without any gaps. You may want to use additional finger pressure or chewies.</p>
                <img src={alignerPic} alt="aligner demo pic" height={180}  />
                <p className="black-color font-weight-bold text-center font-size-12 my-2">Place aligners on one or both jaws as applicable to you.</p>
            </div>
        )
    }

    return (
        <Dialog onClose={handleClose} open={open} className="rating-container">
            <div className="p-4 mb-3">
                <h2 className="text-center font-size-20 primary-color">INFO</h2>
                <p className="black-color font-weight-bold text-center font-size-16 my-2">
                    {message}
                    {/* <img src={TorchIcon} width="20" alt="TorchIcon" /> */}
                </p>
              { !message.includes("without") && <AlignerInfo /> }
            </div>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default MessageDialog;