import * as types from '../Action';

const initState = {
  message: undefined
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  const response = action.payload;

  switch (action.type) { 
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        response,
        message: "",
      };
    case types.LOGIN_USER_ERROR:
      return {
        ...state,
        response,
        message : response.data.message,
      }

    default:
      return state;
  }
};
