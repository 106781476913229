import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Slider } from "@material-ui/core";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CavityImg from "../../../assets/Dashboard/new-images/cavity.svg";
import TartarImg from "../../../assets/Dashboard/new-images/tartar.svg";
import InsightsImg from "../../../assets/Dashboard/new-images/insights.svg";
import Update from "../../../assets/Dashboard/update.svg"
import DentalRiskImg from "../../../assets/Dashboard/new-images/dental-risk.svg";
import Button from "@material-ui/core/Button";

import {
  getReportsCount,
  getLatestReport,
  getQuestionnaireById,
  getPopupDate
} from "../../../Redux/Services/AuthService";
import PwaDialog from "./PwaDialog";
import SnackBarMessages from "../../UI/Snackbar/Snackbar";
import ExplainerCard from "../../UI/Card/ExplainerCard/ExplainerCard";
import { CavityAndTartarMarks, DentalRiskScoreMark } from "./SliderMarks";
import SendReportBtnUI from "../TotalTest/Report/SendReportBtnUI";
import ReviewFlagExplainer from "../../UI/Card/ExplainerCard/ReviewFlagExplainer";
import ConsultOnlineDialog from "./ConsultOnlineDialog";
import Popup from "./Popup";
import "../dashboard.css";
import ButtonImg from "../../../assets/Dashboard/new-images/btn-image.svg";
import ScanBtn from "../../UI/Buttons/ScanBtn";
import moment from "moment";
import { getAlignerReportCount, userInfo } from "../../../Redux/Services/AlignerService";
import ProfileSkeleton from "../../UI/skeleton/profileSkeleton";
import User from "./userDetails";

class Dashboard extends Component {
  constructor(props) {
    super();
    this.cavityTartarSliderMarks = CavityAndTartarMarks;
    this.dentalRiskScoreMark = DentalRiskScoreMark;

    this.state = {
      userLoading: true,
      profile: '',
      aligner: '',
      userError: '',
      cavity: 0,
      tartar: 0,
      cavitySliderValue: 0,
      tartarSliderValue: 0,
      first_name: "",
      last_name: "",
      reportCount: 0,
      dentalScore: 0,
      dentalScoreStatus: "",
      dentalRiskSliderValue: 0,
      isSnackbar: false,
      isExplainerCard: false,
      isGradeExplainerCard: false,
      reportId: '',
      isShowSendReportExplainer: JSON.parse(localStorage.getItem('isShowSendReportExplainer')) === null ? true : false,
      isQuestionnaireTaken: false,
      isShowConsultPopup: false,
      consultMessage: '',
      popup: 0,
      trigger: false,
      quickScanCheckup: false,
      latestReport: "",
      reportStatus: null,
      currentAligner: 0,
      isAlignerCheckCompleted: false,
      isTreatmentCompleted: false
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user")
    if (user) { this.getUser() }

  }

  componentDidUpdate() {
    if (this.props.reportUploadResponse?.status === 200) {
      localStorage.removeItem('report');
    }
  }

  getUser = async (user) => {
    this.setState({ userLoading: true })
    await userInfo().then((res) => {
      const { profile, aligner } = res?.data?.data
      this.setState({ profile, aligner, userLoading: false })
      const alignerDetails = { currentAligner: aligner.current_aligner, isAlignerCheckCompleted: aligner.current_aligner === aligner.total_aligners }
      localStorage.setItem("alignerDetails", JSON.stringify(alignerDetails))


    }).catch((err) => {
      this.setState({ userLoading: false })
    })

  }

  OnCloseDialog() {
    this.setState({ trigger: false });
  }
  explainerCardHandler() {
    return setTimeout(() => {
      if (localStorage.getItem("isExplainerCard") && !(this.state.trigger)) {
        this.setState({ isExplainerCard: true });
      }
    }, 1000);
  }

  sliderActiveClassHandler(element, cssClass) {
    const dental = document.getElementById(element);
    const cssClasses = dental.querySelectorAll(
      ".MuiSlider-markLabel.MuiSlider-markLabelActive"
    );
    cssClasses[cssClasses.length - 1].classList.add("active-slider");
    cssClasses[cssClasses.length - 1].classList.add(cssClass);
  }

  getReportCountServiceHandler() {
    getReportsCount()
      .then((res) => {
        if (res && res.data.data !== "No Data") {
          this.getLatestReportServiceHandler();
          this.getQuestionnaireDetails();
          localStorage.setItem('reportCount', JSON.stringify(res.data.data));
          this.setState({ reportCount: res.data.data });
          if (localStorage.getItem("isGradeExplainer") && this.state.reportCount === 1) {
            this.setState({ isGradeExplainerCard: true });
          }
        } else {
          this.explainerCardHandler();
        }
      })
      .catch((err) => { });
  }

  getLatestReportServiceHandler() {
    getLatestReport()
      .then((res) => {
        if (res && res.data.data.length > 0) {
          // calculation score of cavity
          const score = res.data.data[0].report_score[0].overall_score[1];
          const reportId = res.data.data[0]._id;
          const userId = res.data.data[0].user_id;
          this.setState({ reportId, userId });
          this.cavityCalculation(score);

          // calculation score of tartar
          const tartarScore = res.data.data[0].report_score[0].overall_score[0];
          this.tartarCalculation(tartarScore);

          // This is for consult popup
          if (localStorage.getItem("isGradeExplainer") && this.state.reportCount === 1) {
            const message = this.getConsultMessage(score, tartarScore);
            if (message) {
              this.setState({ isShowConsultPopup: true, consultMessage: message });
            }
          }

        } else {
          if (localStorage.getItem("isExplainerCard") && this.state.reportCount === 1) {
            this.setState({ isExplainerCard: true });
          }
        }
      })
      .catch((err) => { });
  }

  cavityCalculation(score) {
    if (score === 0) {
      this.setState({ cavitySliderValue: 0 });
      this.markGradePointer('left-side-popover', 3);
      this.sliderActiveClassHandler("cavitySlider", "text-success");
    } else if (score === 1) {
      this.markGradePointer('left-side-popover', 11);
      this.setState({ cavitySliderValue: 75 });
      this.sliderActiveClassHandler("cavitySlider", "text-danger");
    } else if (score > 1) {
      this.markGradePointer('left-side-popover', 20);
      this.setState({ cavitySliderValue: 100 });
      this.sliderActiveClassHandler("cavitySlider", "text-danger");
    }
    this.setState({ cavity: score });
  }

  tartarCalculation(tartarScore) {
    if (tartarScore <= 6) {
      this.markGradePointer('right-side-popover', 38);
      this.sliderActiveClassHandler("tartarSlider", "text-success");
    } else if (tartarScore > 6 && tartarScore <= 12) {
      this.markGradePointer('right-side-popover', 28);
      this.setState({ tartarSliderValue: 25 });
      this.sliderActiveClassHandler("tartarSlider", "text-success");
    } else if (tartarScore > 12 && tartarScore <= 18) {
      this.markGradePointer('right-side-popover', 18);
      this.setState({ tartarSliderValue: 50 });
      this.sliderActiveClassHandler("tartarSlider", "text-warning");
    } else if (tartarScore > 18 && tartarScore <= 24) {
      this.markGradePointer('right-side-popover', 8);
      this.setState({ tartarSliderValue: 75 });
      this.sliderActiveClassHandler("tartarSlider", "text-danger");
    } else if (tartarScore > 24) {
      this.markGradePointer('right-side-popover', -8);
      this.setState({ tartarSliderValue: 100 });
      this.sliderActiveClassHandler("tartarSlider", "text-danger");
    }
    this.setState({ tartar: tartarScore });
  }

  markGradePointer(className, value) {
    if (className === 'left-side-popover') {
      return document.styleSheets[0].insertRule(`.${className}::after { left: ${value}% !important; }`, 0);
    } else {
      return document.styleSheets[0].insertRule(`.${className}::after { right: ${value}% !important; }`, 0);
    }
  }

  getQuestionnaireDetails() {
    let userId = JSON.parse(localStorage.getItem("user"))?.user._id;
    if (userId) {
      getQuestionnaireById(userId)
        .then((res) => {
          if (res && res.data.data.length > 0) {
            this.setState({ isQuestionnaireTaken: true });
            // calculation score of dental score
            const score = res.data.data[0].score;
            if (score < 1.5) {
              this.setState({
                dentalScoreStatus: "Low",
                dentalRiskSliderValue: 0,
              });
              this.sliderActiveClassHandler("dentalSlider", "text-success");
            } else if (score > 1.5 && score < 2.5) {
              this.setState({
                dentalScoreStatus: "Medium",
                dentalRiskSliderValue: 50,
              });
              this.sliderActiveClassHandler("dentalSlider", "text-warning");
            } else if (score > 2.5) {
              this.setState({
                dentalScoreStatus: "High",
                dentalRiskSliderValue: 100,
              });
              this.sliderActiveClassHandler("dentalSlider", "text-danger");
            }
            this.setState({ dentalScore: score });
          }
        })
        .catch((err) => { });
    }
  }

  handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      this.setState({ value: newValue });
    }
  };

  handlePwaPopup = (data) => {
    this.setState({ isSnackbar: data });
  };

  getConsultMessage(cavityScore, tartarScore) {
    if (cavityScore > 0 && tartarScore > 0) {
      return 'Cavity/Tartar detected!'
    } else if (cavityScore > 0) {
      return 'Cavity detected!';
    } else if (tartarScore > 0) {
      return 'Tartar detected!';
    }
    return '';
  }


  render() {
    const { profile, aligner, userLoading } = this.state
    const loggedIn = localStorage.getItem("user")

    return (
      <Container fluid className="mt-5 max-width-450px">
        {loggedIn ? userLoading ? <ProfileSkeleton /> : profile && aligner ? <User profile={profile} aligner={aligner} /> : <p className="text-center py-5">Something went wrong.</p> : <div className="text-capitalize black-color font-size-20 font-weight-500 profile w-70 pt-4">Hi Guest!!!</div>}

        {/* Insights */}
        <Col xs={12} sm={12} className="my-4 d-flex justify-content-center animate__animated animate__fadeInUpBig">
          <Link to="/total-test">
            <div className="dashboard-box p-3 dashboard-box-width-100">
              <div className="d-flex my-2 font-size-19 black-color font-weight-500">
                <div>Previous Report(s)</div>
                <div className="flex-grow-1 text-right font-size-12 mt-2">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>
              <div className="my-2">
                <img src={InsightsImg} alt="tartar" />
              </div>
              <div className="d-flex align-items-end flex-column mt-3">
                {
                  aligner &&
                  <>
                    <div className="font-size-20 font-weight-bold black-color">
                      {aligner.report_count}
                    </div>
                    <div className="black-color">
                      <small>Report(s)</small>
                    </div>
                  </>
                }

              </div>
            </div>
          </Link>
        </Col>

        {/* click to scan button */}
        <Row className="mt-1">


          {
            this.state.quickScanCheckup && <Col
              xs={12}
              className="p-0 d-flex flex-column align-items-end justify-content-end"
            >
              <ScanBtn
                name="Click here for Quick Scan"
                path="new-scan"
                isQuickScan={true}
              // bottomText="1-2 photos"
              />
            </Col>
          }


          {!this.state.quickScanCheckup && <Col
            xs={12}
            className="p-0 d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUpBig animate__delay-1s"
          >
            <Link className="w-100 text-center"
              to={"instruction-for-use"}>
              <button className="animate-btn">
                <img className={`w-240px mt-2 pl-4`} src={ButtonImg} alt="button img" /> <br />
                <span className="black-color position-relative top--48px">Please Take Photos to Create a Report</span>
              </button>
            </Link>
          </Col>}

        </Row>

        <PwaDialog handlePwaPopup={this.handlePwaPopup.bind(this)} />
        {
          this.state.consultMessage && (
            <ConsultOnlineDialog isOpenDialog={this.state.consultMessage ? true : false} message={this.state.consultMessage}
              reportId={this.state.reportId} />
          )
        }



        {/* snackbar */}
        {this.state.isSnackbar && (
          <SnackBarMessages
            open={this.state.isSnackbar}
            message="App installed successfully "
            timing={5000}
            type="success"
          />
        )}
        {this.state.trigger && <Popup open={this.state.trigger} onClose={this.OnCloseDialog.bind(this)} />}
      </Container>
    );
  }
}

const mapDespatchToProps = (dispatch) => {
  return {
    reportUpload: (reportUpload) => {
      dispatch({ type: "USER_REPORT_UPLOAD", reportUpload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loginResponse: state.login.response,
    reportUploadResponse: state.reportUpload.response
  };
};
export default withRouter(connect(mapStateToProps, mapDespatchToProps)(Dashboard));