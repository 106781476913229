import * as types from "../Action";

const initState = {
  responseImages: [
    {
      title: "Front Teeth",
      key: "front_teeth"
    },
    {
      title: "Upper Jaw",
      key: "upper_jaw"
    },
    {
      title: "Lower Jaw",
      key: "lower_jaw"
    },
    {
      title: "Left Side",
      key: "left_side"
    },
    {
      title: "Right Side",
      key: "right_side"
    },
    {
      title: "Aligner Front Teeth",
      key: "aligner_front_teeth"
    },
    {
      title: "Aligner Upper Jaw",
      key: "aligner_upper_jaw"
    },
    {
      title: "Aligner Lower Jaw",
      key: "aligner_lower_jaw"
    },
    {
      title: "Aligner Left Side",
      key: "aligner_left_side"
    },
    {
      title: "Aligner Right Side",
      key: "aligner_right_side"
    },
  ],
  imageCount: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  switch (action.type) {
    case types.USER_REPORT_SUCCESS:
      let newArray = state.responseImages;
      newArray[action.payload.imgCount - 1].image_id = action.payload.imgCount;
      newArray[action.payload.imgCount - 1].image_name = action.payload
        ? action.payload.data.imageName
        : "";
      return {
        ...state,
        responseImages: newArray,
        imageCount: action.payload.imageCount,
      };
    case types.USER_REPORT_RESET:
      const stateData = {...state};
      const updatedState = stateData.responseImages.map((img) => (
        {
          title: img.title,
          key: img.key
        }
      ));
      stateData.responseImages = updatedState;
      stateData.imageCount = 0;
      return stateData;
    case types.USER_REPORT_ERROR:
      return {};
    default:
      return state;
  }
}
