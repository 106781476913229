import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Navbar } from "react-bootstrap";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScanTooth from "../../assets/LoginPage/scan-tooth-image.png";
import tele from "../../assets/logo/logo.jpeg";
import logo from "../../assets/logo/logo67x67.svg";
import HippaLogo from "../../assets/logo/hipaa.jpg";
import alignCheckLogo from "../../assets/alignCheckLogos/ALIGN-PNG_1.png"

import { FormatColorResetOutlined } from "@material-ui/icons";

import "./../../stylesheets/common.css";
import classes from "./landing.module.css";
import IntroVideoModal from "../UI/Modal/IntroVideoModal";
import {
  getAppsDetails,
  getBinaryDataOfClinicImage,
} from "../../Redux/Services/AppsService";

class Landing extends Component {
  constructor() {
    super();
    this.state = {
      isIntroVideo: true,
      loading: true,
      photo: false,
      imgs: [],
    };
  }

  componentDidMount() {

    // console.log(JSON.parse(localStorage.getItem("imageUrl")))
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return this.props.history.push("/dashboard");
    } else {
      const appDetails = JSON.parse(localStorage.getItem("appDetails"));
      const imageblob = JSON.parse(localStorage.getItem("imageblob"))

      if (imageblob) {
        const byteArray = new Uint8Array(imageblob); // Convert the array of integers to a byte array
        const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
        const imageUrl = URL.createObjectURL(blob);
        this.setState({ imgs: [imageUrl] })
        this.setState({ photo: true })
      }
      if (!appDetails || !imageblob) {
        getAppsDetails(window.location.origin).then(
          async (resData) => {
            if (resData.data.data !== "No Data") {
              localStorage.setItem(
                "appDetails",
                JSON.stringify(resData.data.data)
              );

              this.setState({ loading: false });
              await getBinaryDataOfClinicImage(resData.data.data.app_logo_file)
                .then((res) => {
                  if (res.status === 200) {
                    const byteArray = new Uint8Array(
                      res.data.data.binaryData.data
                    ); // Convert the array of integers to a byte array
                    const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
                    const imageUrl = URL.createObjectURL(blob);
                    localStorage.setItem('imageblob', JSON.stringify(res.data.data.binaryData.data));
                    this.setState({ photo: true })
                    this.setState({ imgs: [imageUrl] })
                  }
                })
                .catch((err) => {
                  this.setState({ photo: false });
                });
            }
            this.props.history.push("/");
          },
          (err) => {
            this.props.history.push("/");
          }
        );
      }
    }
  }

  OnCloseDialog() {
    this.setState({ isIntroVideo: false });
  }

  render() {
    const settings = {
      dots: FormatColorResetOutlined,
      infinite: true,
      autoplaySpeed: 2000,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // fade: true,
      cssEase: "linear",
    };

    const appDetails = JSON.parse(localStorage.getItem("appDetails"))

    return (
      <div>
        <Navbar
          expand="lg"
          className={`${classes["navbar"]} overflow d-flex align-items-center justify-content-center`}
        >
          <Container fluid>
            <div className="d-flex w-100 align-items-center justify-content-between">
              {/* <img src={logo} width="48" alt="QR code img" /> */}

              {this.state.photo ? (
                <img
                  src={this.state.imgs[0]}
                  width=""
                  height="50"
                  alt="QR code img"
                  style={{ maxWidth: "48%" }}
                />
              ) : (
                ""
              )}

              {appDetails ? (
                <div className="d-flex flex-column justify-content-end align-items-end " style={{ fontSize: "8px", lineHeight: "10px", textAlign: "end" }}>
                  <span className="primary-color font-weight-600 my-auto font-size-11 text-uppercase mb-3" style={{ lineHeight: "15px" }}>{appDetails.app_name}</span>
                  <span className="font-weight-600 my-auto  ">{appDetails.app_email}</span>
                  <span className="font-weight-600 my-auto ">{appDetails.app_phone_number}</span>
                  <span className="font-weight-600 my-auto ">{appDetails.app_address}, {appDetails.app_city}</span>
                  <span className="font-weight-600 my-auto ">{appDetails.app_state}, {appDetails.app_country}, {appDetails.app_zip_code}</span>
                </div>
              ) : (
                ""
              )}
              {/* <div className={`${classes['navbar-text']}`} >{JSON.parse(localStorage.getItem('appDetails')).app_name}</div> */}
            </div>
          </Container>
        </Navbar>
        <Container
          fluid
          className={`${classes["landing-bg"]} overflow d-flex align-items-center justify-content-center mt-4`}
          style={{ height: `${window.innerHeight}px` }}
        >
          <Row className="w-100">
            <Col className="mt-1">
              <Slider {...settings}>
                <div className={`${classes["slider-slide2"]} `}>
                  <img
                    src={ScanTooth}
                    alt="recepit"
                    className="d-block mx-auto"
                  />
                  <h5 className="text-center mt-1 mb-2 primary-color font-weight-600">
                    Click, Scan and Check
                  </h5>
                </div>
              </Slider>
            </Col>
          </Row>
          <Row className="w-100">
            <Col className="d-block text-center">
              {/* <Link to="/dashboard">
                        <button type="submit"
                            className={`${classes['landing-btn']}
                            mx-auto mb-3 align-items-center primary-bg text-white font-weight-600
                        `}>Say Cheese !</button>
                    </Link> */}
              <Link to="/login">
                <button
                  type="submit"
                  className={`${classes["landing-btn"]}
                            my-2 mx-auto mx-auto align-items-center primary-bg text-white font-weight-600
                            `}
                >
                  Enter with a Smile 😀
                </button>
              </Link>
              {/* <Link to="/signup">
                        <button type="submit"
                            className={`${classes['landing-btn']}
                            my-2 mx-auto mx-auto align-items-center primary-bg text-white font-weight-600
                            `}>Signup</button>
                    </Link> */}
              <div className=" mx-auto mb-3 mt-5 d-flex justify-content-center align-items-center text-white font-weight-600">
                Powered by Toothlens Aligncheck  <span className="m-2 rounded"> <img src={alignCheckLogo} width="32" alt="QR code img" /> </span>
              </div>
              <div>
                <img src={HippaLogo} alt="hippa logo" width="25" />
              </div>
            </Col>
          </Row>
          {/* {this.state.isIntroVideo && <IntroVideoModal open={this.state.isIntroVideo} onClose={this.OnCloseDialog.bind(this)} />} */}
        </Container>
      </div>
    );
  }
}

export default Landing;