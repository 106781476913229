import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Update from "../../../assets/Dashboard/update.svg"





const User = ({ profile, aligner }) => {

    const Status = ({ status }) => {

        let updatedStatus = "Pending"
        let styles = "text-danger"

        if (aligner.is_doctor_reviewed) {
            switch (status) {
                case 1:
                    updatedStatus = "Go";
                    styles = "text-success"
                    break;
                case 2:
                    updatedStatus = "No Go";
                    styles = "text-danger"
                    break;
                case 3:
                    updatedStatus = "Reshoot";
                    styles = "text-warning"
                    break;
                case 0:
                    updatedStatus = "Pending";
                    styles = "text-danger"
                    break;
                default:
                    break;

            }

        }

        return (
            <b className={`font-size-20 text-uppercase
                  animate__animated animate__zoomInDown animate__delay-1s animate__repeat-3 
                  ${styles}`}>
                {updatedStatus}</b>

        )

    }
    return (
        <>
            <Row>
                <Col xs={12} className="d-flex mt-4 animate__animated animate__fadeInDown">

                    <Row>
                        <Col xs={9}>
                            <div className="font-size-20 mx-3 ">{profile.first_name} {profile?.last_name}
                            </div>

                            <div className=" mx-3 ">{profile.gender}</div>
                            <div className=" mx-3 ">DOB: {moment(profile?.dob).format('YYYY-MM-DD')}</div>
                        </Col>
                        <Col xs={2} className=" d-flex justify-content-end align-items-center">
                            <Link to="/profile-details"><Button className="">
                                <img className={`w-20px h-10px`} src={Update} alt="Update img" /></Button> </Link>
                        </Col>
                    </Row>
                    {/* : <div className="text-capitalize black-color font-size-20 font-weight-500 profile w-70">Hi Guest!!!</div>} */}
                </Col>
            </Row>

            <Row className="m-0">
                {
                    aligner.treatment_completed && <Col xs={12} className="pr-0 animate__animated animate__backInLeft animate__flash my-3">
                        <p className="font-size-14 font-weight-bold alert-success rounded p-2">You have successfully completed aligner checks. Please continue using the retainer.</p>
                    </Col>
                }

                {
                    !aligner.treatment_completed && <Col xs={6} className="pr-0 animate__animated animate__backInLeft animate__flash">
                        <span className="font-size-13">Next test date:<br />
                            <b className="font-size-18 animate__animated animate__zoomInDown animate__delay-1s animate__repeat-3">
                                {moment(aligner.next_date_to_take_test).format('YYYY-MM-DD') === moment(new Date()).format("YYYY-MM-DD") ? 'Today' : moment(new Date(aligner.next_date_to_take_test)).format("YYYY-MM-DD")}
                            </b></span>
                    </Col>
                }

                {
                    !aligner.treatment_completed && aligner.report_count > 0 && <Col xs={6} className="text-right animate__animated animate__backInRight animate__flash">
                        <span className="font-size-13">Current report status: <br />    <Status status={aligner.report_go_status} />  </span>
                    </Col>
                }
            </Row>

            <Row className="m-0">
                {!aligner.treatment_completed && <Col xs={12} className="pr-0 animate__animated animate__backInLeft animate__flash">
                        <span className="font-size-13">Current Aligner:
                            <b className="font-size-18 animate__animated animate__zoomInDown animate__delay-1s animate__repeat-3 ml-1">{aligner.current_aligner}</b></span>
                    </Col>
                }
            </Row>
        </>

    )
}

export default User