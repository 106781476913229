import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import CheckCircle from "./../../../assets/cameraModule/check-circle-1.svg";
import { CircularProgress } from "@material-ui/core";

const ToothPhotosSubmitModal = (props) => {
  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-around mx-auto px-0">
        <div className="all-well-modal p-3">
          <div className="text-center">
            <h3 className="login-createOne my-3 mx-4 font-size-16">
              <span>You have captured all 5 pictures successfully</span>
            </h3>
          </div>
          <div className="text-center">
            <img src={CheckCircle} alt="SuccessTeeth" />
          </div>
          {/* <div className="text-center">
            <h3 className="login-createOne my-3 mx-4 font-size-16">
              <span>Press Submit to analyse the photos</span>
            </h3>
          </div> */}
          <div className="text-center mb-3">
            {
              props.isPhotoUploaded ?
                <Button
                  className="text-center font-size-15 text-uppercase primary-bg font-weight-bold text-white w-100"
                  variant="contained"
                  size="sm"
                  onClick={() => props.onSubmit(true)}
                >
                  Submit and Share with dental office
                </Button> : <CircularProgress className="p-2 mt-2" />
            }
          </div>
          {/* <div className="text-center mb-3">
            <Button
              className="text-center font-size-15 text-uppercase  font-weight-bold  w-50 py-3"
              variant="contained"
              size="sm"
              onClick={() => props.onSubmit(false)}
            >
              Submit
            </Button>
          </div>  */}
        </div>
      </Col>
    </Row>
  );
};

export default ToothPhotosSubmitModal;
