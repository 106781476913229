import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = (props) => {

    const userExist = JSON.parse(localStorage.getItem("user"));

    if (userExist) {
        return <Route {...props} />
    } else {
        return <Redirect to="/login" />

    }
}

export default ProtectedRoute