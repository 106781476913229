import React,{useEffect} from "react";
import { Row, Col, Button } from "react-bootstrap";
import SuccessTeeth from "./../../../assets/cameraModule/Success.svg";
import FailedTeeth from "./../../../assets/cameraModule/Failed.svg";
import { useHistory } from "react-router";
import { connect } from "react-redux";

const MessageModal = (props) => {
  let history = useHistory();
  let userId = JSON.parse(localStorage.getItem("user"))?.user._id;

  const redirectToDashboard = () => {
    localStorage.removeItem('report');
    if (props.isSuccess) {
      localStorage.setItem('isGradeExplainer', true);
    }
    props.resetReport();
    props.removeAllImages();
    props.resetReportUpload();
    // if (props.isSubmitAndShare) {
    //   return history.push(`/select-branches/${props.reportId}`, {
    //     userId
    //   })
    // }
    localStorage.removeItem('alignerPhotos');
    return history.push("/dashboard");
  }
useEffect(()=>{
  // redirectToDashboard();
},[])

  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-around mx-auto px-0">
        <div className="all-well-modal p-3">
          <div className="text-center">
            <img src={props.isSuccess ? SuccessTeeth : FailedTeeth} alt="SuccessTeeth" />
          </div>
          <div className="text-center">
            <h3 className="login-createOne my-3 mx-4 font-size-16">
              <span>
                {
                  props.isSuccess ? `${props.isQuickScan ? props.message : 'Thanks for submitting your photos.Go to Dashboard to see your reports.'}` :
                    'Sorry!! Something went wrong. Please rescan after 5 minutes'
                }
              </span>
            </h3>
          </div>
          <div className="text-center mb-3">
            <button
              type="submit"
              className="modal-signup-btn mt-2"
              onClick={redirectToDashboard}
            >
              Go to dashboard
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapDespatchToProps = (dispatch) => {
  return {
    resetReport: () => {
      dispatch({ type: "USER_REPORT_UPLOAD_RESET" });
    },
    removeAllImages: (imageData) => {
      dispatch({ type: "REMOVE_ALL_IMAGES" });
    },
    resetReportUpload: (report) => {
      dispatch({ type: "USER_REPORT_RESET" });
    },
  };
};

export default connect(null, mapDespatchToProps)(MessageModal);
