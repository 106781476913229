import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import FlashOffIcon from "@material-ui/icons/FlashOff";
import { IconButton } from "@material-ui/core";
import CameraFrontIcon from "@material-ui/icons/CameraFront";
import CameraRearIcon from "@material-ui/icons/CameraRear";
import CameraIcon from '@material-ui/icons/Camera';
import CameraCancelButton from "../../UI/CameraModule/CameraCancelButton";
import { GetMobileOperatingSystem } from "./common";
import CameraAccessDialog from "../Dialog/CameraAccessDialog";
import CameraErrorPopup from "../../UI/Dialog/CameraErrorPopup";
import { GetBrowserDetails, GetDeviceDetails, GetOSDetails } from "../../Utils/Device/deviceDetails";
import { updateCameraPermissionStatus } from "../../../Redux/Services/ReportService";
import MessageDialog from "../../UI/Dialog/messageDialog";
import QuickScanTutorialDialog from "../../UI/Dialog/QuickScanTutorialDialog";
import "./CameraModule.css";
import CameraModuleReshot from "./CameraModuleReshot";

const CameraModuleFirst = (props) => {
  const [isCamera, setCamera] = useState(props.isCamera)
  const [reshootCameraData, setReshootData] = useState(props.state)
  const [flashLight, setFlashLight] = useState(false);
  const [cameraFacingMode, setCameraFacingMode] = useState("ENVIRONMENT");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [isShowCameraAccessDialog, setIsShowCameraAccessDialog] = useState(false);
  const [saveStream, setSaveStream] = useState('');
  const [isShowCameraErrorPopup, setIsShowCameraErrorPopup] = useState(false);
  const [cameraErrorMessage, setCameraErrorMessage] = useState('');
  const [isShowMessagePopup, setIsShowMessagePopup] = useState(false);

  const demoImageData = props.state?.data;
  let demoImage = props.state?.data?.image;
  let imgCount = props.state?.imgCount;
  let overlay = props.state?.data?.overlay;
  const isQuickScan = props.state?.isQuickScan;
  let history = useHistory();
  const fullscreen = true;

  const handleTakePhotoAnimationDone = (dataUri) => {
    flashLightHandler(false);
    setReshootData({
      dataUri,
      demoImage,
      imgCount,
      data: demoImageData,
      isQuickScan
    });

    setTimeout(() => {
      setCamera(false)
    }, 500);
  };
  const setRef = (stream, flashLight = true) => {
    setSaveStream(stream);
    const track = stream.getVideoTracks()[0];
    if (track) {
      track
        .applyConstraints({
          advanced: [{ torch: cameraFacingMode === 'ENVIRONMENT' ? flashLight : false }], // zoom: 2.5
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    imgCount === 1 && setIsShowMessagePopup(true);
    const getOperatingSystem = GetMobileOperatingSystem();
    setOperatingSystem(getOperatingSystem);

    return () => {
      setFlashLight(false);
    };
  }, []);

  const toggleTorch = () => {
    if (flashLight) {
      setFlashLight(false);
      setRef(saveStream, false);
    } else {
      setFlashLight(true);
      setRef(saveStream, true);
    }
  };

  const cameraFacingModeHandler = () => {
    if (cameraFacingMode === "ENVIRONMENT") {
      setCameraFacingMode("USER");
    } else {
      setCameraFacingMode("ENVIRONMENT");
    }
  };

  const flashLightHandler = (isTorchOpen) => {
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: "environment", zoom: true },
      })
      .then((stream) => {
        const video = document.querySelector("video");
        video.srcObject = stream;

        // get the active track of the stream
        const track = stream.getVideoTracks()[0];

        video.addEventListener("loadedmetadata", (e) => {
          window.setTimeout(() => {
            onCapabilitiesReady(track.getCapabilities());
          }, 500);
        });

        function onCapabilitiesReady(capabilities) {
          if (capabilities.torch) {
            track
              .applyConstraints({
                advanced: [{ torch: isTorchOpen }], // zoom: 2.5
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      })
      .catch((err) => console.error("getUserMedia() failed: ", err));
  };

  const handleCloseDialog = () => {
    setIsShowCameraAccessDialog(false);
  }

  const handleCameraErrorPopup = () => {
    setIsShowCameraErrorPopup(false);
  }

  const closeMessageDialog = () => {
    setIsShowMessagePopup(false);
  }

  return (
    <>

      {isCamera ? <Container fluid className="bg-container p-0">
        <Camera
          onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          isFullscreen={fullscreen}
          idealFacingMode={FACING_MODES[cameraFacingMode]}
          isImageMirror={false}
          imageType={IMAGE_TYPES.JPG}
          className="WebCam"
          isMaxResolution={true}
          imageCompression={0.6}
          onCameraStart={async (stream) => {
            setRef(stream, flashLight);
            const checkCameraPermission = JSON.parse(localStorage.getItem('cameraPermission'));
            if (!checkCameraPermission) {
              localStorage.setItem('cameraPermission', true);
              await updateCameraPermissionStatus({ camera_permission_status: 'Access' });
            }
          }}
          onCameraError={async (err) => {
            localStorage.setItem('cameraPermission', false);
            await updateCameraPermissionStatus({ camera_permission_status: 'Denied' });

            // Getting all device details
            const device = GetDeviceDetails();
            const osDetails = GetOSDetails();
            const browser = GetBrowserDetails();
            if (browser.name === 'Chrome') {
              navigator.permissions.query({ name: "camera" }).then(res => {
                if (res.state === "denied" && osDetails.name === 'iOS') {
                  setCameraErrorMessage('iosError');
                  setIsShowCameraErrorPopup(true);
                } else if (res.state === "denied" && osDetails.name === 'Android' && device.vendor === 'OnePlus') {
                  setIsShowCameraAccessDialog(true);
                } else if (res.state === "denied" && osDetails.name === 'Android') {
                  setCameraErrorMessage('androidError');
                  setIsShowCameraErrorPopup(true);
                }
              });
            } else {
              setCameraErrorMessage('iosError');
              setIsShowCameraErrorPopup(true);
            }
          }}
        ></Camera>

        <Row className="position-absolute w-100 h-100 top-0 bottom-0 left-0 right-0 m-0">
          <CameraAccessDialog open={isShowCameraAccessDialog} onClose={handleCloseDialog} />
          <CameraErrorPopup open={isShowCameraErrorPopup} errorMessage={cameraErrorMessage} onClose={handleCameraErrorPopup} />
          <Col className="p-0">
            <Row className="overlay-blur mx-0 justify-content-center">
              <Col xs={12} className="text-right top-10 cancel-button-aligncheck">
                <button className="bg-transparent border-0" onClick={() => { props.setCameraModule(true) }}>
                  <i className="fas fa-times camera-cancel-btn font-size-23 text-white d-flex justify-content-center align-items-center"></i>
                </button>
              </Col>
              {operatingSystem !== "iOS" && (
                <Col xs={1} className="p-0">
                  <IconButton className="text-white torch-icon-aligner" onClick={toggleTorch}>
                    {flashLight ? <FlashOffIcon /> : <FlashOnIcon />}
                  </IconButton>
                </Col>
              )}
              <Col xs={10}>
                <div className="text-overlay">
                  <p>{demoImageData.title}</p>
                  <p style={{ fontSize: 15 }}>{demoImageData.headerText}</p>
                  <p style={{ fontSize: 15 }}>{demoImageData.headerMirrorText}</p>
                </div>
              </Col>
              {/* <Col xs={1}>
              <div className="camera-facing-icon">
                  <IconButton
                    style={{ color: "white" }}
                    onClick={cameraFacingModeHandler}
                  >
                    {cameraFacingMode === "USER" ? (
                      <CameraRearIcon />
                    ) : (
                      <CameraFrontIcon />
                    )}
                  </IconButton>
                </div>
            </Col> */}
            </Row>

            <Row className="height-56">
              <Col xs={1} className="p-0 h-100 black-bg"></Col>
              <Col xs={10} className="p-0">
                <div className="overlay">
                  <div className="overlay-element top-left"></div>
                  <div className="overlay-element top-right"></div>
                  <div className="overlay-element bottom-left"></div>
                  <div className="overlay-element bottom-right"></div>

                  {/* Overlay images */}
                  {
                    overlay && !isQuickScan && (
                      <div className={`tooth-overlay-1 overlay-container-${demoImageData.number} `}>
                        {/* <img src={overlay} alt="overlay" className={`aligner-overlay-${demoImageData.number}`}  /> */}
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col xs={1} className="p-0 h-100 black-bg"></Col>
            </Row>

            <div className="bottom-text-overlay d-flex pt-4">
              <p style={{ fontSize: 15 }} className="animation d-flex  justify-content-center"> < CameraIcon className="mx-2" />  Click anywhere to capture the photo</p>
              <p className="pt-2 bottom-mirror-text font-size-17">
                {demoImageData.bottomMirrorText ? demoImageData.bottomMirrorText : 'Hope you are in front of the mirror.'}
              </p>
            </div>
          </Col>
        </Row>
      </Container> : <CameraModuleReshot state={reshootCameraData} setCamera={setCamera} reshoot={props.reshoot}  setCameraModule={props.setCameraModule} />}
    </>
  );
};

export default CameraModuleFirst;
