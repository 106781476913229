// export const API_HOST = "http://localhost:3000/api/v1/"; 

// export const API_HOST = "https://alignerdev.aligncheck.ai/api/v1/";

export const API_HOST = "https://aligner.aligncheck.ai/api/v1/";



export const CAVITY_IMG_URL = "https://toothlens-image-store.s3.ca-central-1.amazonaws.com/output/cavity/";
export const TARTAR_IMG_URL = "https://toothlens-image-store.s3.ca-central-1.amazonaws.com/output/tartar/";
export const REPORT_IMG_URL = "https://toothlens-image-store.s3.ca-central-1.amazonaws.com/input/";