const imageReducer = (state = [], action) => {
    if (action.type === 'ADD_IMAGE') {
        const imgArray = [...state];
        imgArray[action.payload.imgCount - 1] = action.payload.imageUrl;
        return imgArray;
    } else if (action.type === 'REMOVE_ALL_IMAGES') {
        return [];
    }
    return state;

};
export default imageReducer;