import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './CardSlider.css';

const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
    // centerMode: true,
    cssEase: "linear",
};

const CardSlider = ({ sliderImages }) => {
    return (
        <Slider {...settings}>
            {sliderImages.map((item, index) => {
                return (
                    <div key={item.name} className="cavity-slider">
                        <div className="mb-2 text-capitalize primary-bg text-white position-absolute image-title">
                            <b>{item.name.split("_").join(" ")}</b>
                        </div>
                        <Card className="cavity-card w-100">
                            <Card.Img
                                variant="top"
                                src={item.imageUrl}
                                alt="report images"
                            />
                        </Card>
                    </div>
                );
            })}
        </Slider>
    )
}

export default CardSlider;